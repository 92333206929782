import { mapActions, mapState } from 'vuex'
import { default as itemsBase } from '../js/items'
import state from '../../store/state'

export default {
  data: () => ({
      selectedItem: { },
      items: itemsBase,
  }),
  computed: {
    ...mapState('auth', ['user', 'acesso']),

    teacherId(){
      return (this.user && this.user.id) ? this.user.id : 0;
    },

    workTimeId(){
      return (this.acesso && this.acesso.periodo) ? this.acesso.periodo.id : 0;
    }

  },

  mounted() {
    
    let token = this.$route.query.token;
    const token2 = localStorage.getItem("token");

    if(token){
      this.reloadLogin(token);
    }else if(token2){
      this.reloadLogin(token2);
    }else{
      this.$router.push({ path: '/login/teacher' });
    }

  },

  methods: {
    ...mapActions('auth', ['ActionDoLoginTeacherReload', 'ActionSetToken']),

    reloadLogin(token){

      const url = new URL(window.location);
      const newUrl = new URL(url.origin + url.pathname);
      window.history.pushState({}, '', newUrl);

      this.ActionSetToken(token);
      
      this.ActionDoLoginTeacherReload()
            .then((res) => {
                
            })
            .finally(() => {
                
            });

    },
    
    openForm(item, index){
      
      this.selectedItem = item;

      for (let index2 = 0; index2 < this.items.length; index2++) {
          if(index == index2){
            this.items[index2].show = true;
          }else{
            this.items[index2].show = false;
          }
      }
    
    },
  }
}