export default [
    { 
        id: 1,
        show: false,
        showMore: false, 
        icon: 'mdi-account-clock-outline', 
        title: 'Disponibilidade', 
        subtitle: 'Disponibilidade Semanal', 
        color: 'primary lighten-2', 
        text: "Cadastro da carga horária e disponibilidade semanal"
      },
      { 
        id: 2, 
        show: false,
        showMore: false, 
        icon: 'mdi-calendar-month-outline', 
        title: 'Diário de Classe', 
        subtitle: 'Cronograma de Aulas', 
        color: 'orange lighten-2', 
        text: "Cadastro de presença no diário das aulas"
      },
]